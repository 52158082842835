// Typography
@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@300;400;600;700;800&display=swap');

// Vendor
@import 'vendor/aos.scss';

// Global
@import 'global/variables.scss';
@import 'global/mixins.scss';
@import 'global/setup.scss';
@import 'global/helpers.scss';

// Components
@import 'components/buttons.scss';
@import 'components/snap-section.scss';
@import 'components/section.scss';
@import 'components/testimonials-section.scss';
@import 'components/header.scss';
@import 'components/hamburger.scss';
@import 'components/footer.scss';
@import 'components/plans.scss';
@import 'components/section-indicator.scss';
@import 'components/quote.scss';
@import 'components/stats.scss';
@import 'components/features.scss';
@import 'components/product-testimonial.scss';
@import 'components/bubble-action.scss';
@import 'components/block-bar.scss';
@import 'components/all-products-section.scss';
@import 'components/scroll-position.scss';
@import 'components/form.scss';

// Compositions
@import 'compositions/home-comps.scss';
@import 'compositions/single-comps.scss';

// Overrides
@import 'global/overrides.scss';
