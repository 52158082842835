.txt-scroll-position {
  width: 110px;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
}

.txt-scroll-position__container {
  width: 4px;
  height: 400px;
  background-color: rgba(0,0,0,.2);
  position: relative;
  border-radius: 10px;
  margin-top: 50px;
}

.txt-scroll-position__progress {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: var(--pink);
  border-radius: 10px;
}

.txt-scroll-position__indicator {
  width: 16px;
  height: 16px;
  background-color: var(--pink);
  border-radius: 16px;
  position: absolute;
  top: 0;
  left: -6px;
}

body[data-theme='footer'] {
  .txt-scroll-position {
    opacity: 0;
  }
}

@media only screen and (max-width: 1024px) {

  .txt-scroll-position {
    display: none;
  }
  
}