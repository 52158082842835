.txt-quote {
  width: 100%;
  max-width: 75%;
  margin: 0 auto;
  min-height: 40vh;
  display: flex;
  justify-content: center;
  align-items: flex-end;


  &.--full {
    min-height: 0;
    margin-bottom: 20vh;
    border-left: 2px solid var(--blue);

    .txt-quote__wrap {
      &:before {
        content: '';
        display: none;
      }
    }

    .txt-quote__text {
      @include fluid-type(24px, 36px, 320px, 1920px);
    }
    
    .txt-quote__content {
      padding-left: 50px;
    }
  }

}

.txt-quote__wrap {
  &:before {
    content: '';
    display: block;
    width: 330px;
    height: 2px;
    background-color: var(--pink);
  }
}

.txt-quote__content {

  padding-left: 400px;
}

.txt-quote__text {
  @include fluid-type(36px, 48px, 320px, 1920px);
  font-weight: 300;

  strong {
    font-weight: 800;
  }
}

.txt-quote__caption {
  @include fluid-type(14px, 16px, 320px, 1920px);
  font-weight: 600;
  margin-top: 10px;
}

@media only screen and (max-width: 1024px) {

  .txt-quote {
    max-width: 85%;
  }

  .txt-quote__content {
    padding-left: 0;
  }

  .txt-quote__wrap {
    position: relative;
    &:before {
      content: '';
      transform: translateY(-20px);
      width: 100%;
    }
  }
}

@media only screen and (max-width: 460px) {
  .txt-quote.--full .txt-quote__content {
    padding-left: 20px;
  }

  .txt-quote.--full {
    margin-bottom: 60px;
  }
}