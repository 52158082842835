.txt-features {
  width: 100%;
  max-width: 75%;
  margin: 0 auto;
  background-color: var(--lightBlue);
  padding: 100px;
  border-radius: 6px;
}

.txt-features__title {
  @include fluid-type(48px, 64px, 320px, 1920px);
  font-weight: 800;
  margin-bottom: 50px;
}

.txt-features__list {
  display: grid;
  grid-gap: 30px;
  
  &.--grid-5 {
    grid-template-columns: repeat(5, 1fr);
  }
}

.txt-features__item {
  
}

.txt-features__icon {
  margin-bottom: 35px;
}

.txt-features__feature-name {
  font-weight: 700;
  margin-bottom: 10px;
}

.txt-features__feature-description {
  font-size: 14px;
}

@media only screen and (max-width: 1024px) {

  .txt-features {
    padding: 30px;
    max-width: 85%;
  }

  .txt-features__list {
    display: flex;
    flex-direction: column;
  }

  .txt-features__icon {
    margin-bottom: 20px;
    margin-top: 50px;
  }
}

@media only screen and (max-width: 460px) {
}