// TEXT CHAT

// Hero
.txt-chat-hero__comp {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  //justify-content: center;
  //align-items: center;
  //transform: translateX(-10px);

  justify-content: flex-end;
  align-items: flex-end;
  transform: translateX(-50px);
}
.txt-chat-hero__comp__item {
  width: 36% !important;
  height: auto;
  margin-bottom: 20px;

  &.middle{
    width: 50% !important;
    margin-right: -110px;
  }

  &:last-child {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 1024px) {
  .txt-chat-hero__comp__item{    margin-bottom: 0px;}
  .txt-chat-hero__comp__item.middle{
    margin-right: -60px;
  }
}

// Lead
.txt-lead-comp {
  font-size: 200px;
  @include fluid-type(160px, 200px, 320px, 1920px);
  font-weight: 800;
  position: absolute;
  right: 50px;
  bottom: -50px;
  z-index: 2;
  color: var(--white); 
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: var(--blue);
}

// Services

.txt-services-comp {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding-right: 40px;

  img {
    width: 80%;
    height: auto;
    margin-bottom: 30px;
  }
}

// SMS MKT

.txt-sms-leads__comp {
  position: absolute;
  right: -100px;
  top: -70px;
  z-index: 2;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.txt-sms-leads__comp__item {
  width: 420px !important;
  height: auto;
  margin-bottom: 15px;
}

// REVIEW ALERT

.txt-review-lead-comp {
  position: absolute;
  width: 55% !important;
  height: auto;
  left: 0;
  top: 35%;
  z-index: 2;
}

// BEACON

.txt-beacon-hero__comp {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;

  img {
    width: 40%;
    height: auto;
    transform: translateX(-30px) translateY(80%);
  }
}

@media only screen and (max-width: 1024px) {
  .txt-sms-leads__comp {
    right: 0;
  }
}

@media only screen and (max-width: 460px) {
  .txt-lead-comp {
    right: 0;
  }

  .txt-sms-leads__comp {
    display: none;
  }
}