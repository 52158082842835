// Structure
.txt-products-section {
  width: 100%;
  height: 100vh;
  scroll-snap-align: start;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  z-index: 1;

  &.--light-blue {
    background-color: var(--lightBlue);
  }

  &.--blue {
    background-color: var(--blue);
  }

  &.--light-pink {
    background-color: var(--lightPink);
  }
}

.txt-products-section__container {
  display: flex;
  max-width: 75%;
  width: 100%;
  margin: 0 auto;
  margin-top: 60px;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.txt-products-section__image {
  width: 50%;

  img {
    width: 100%;
    height: auto;
  }
}

// Text content classes
.txt-products-section__content {
  width: 40%;
  position: relative;
  z-index: 2;
}

.txt-products-section__title {
  @include fluid-type(24px, 120px, 320px, 1920px);
  line-height: 1;
  margin: 0;
  margin-bottom: 30px;

  &.--nowrap {
    white-space: nowrap;
  }

  &.--bigger {
    @include fluid-type(48px, 144px, 320px, 1920px);
  }

  &.--smaller {
    @include fluid-type(36px, 96px, 320px, 1920px);
  }

  &.--super-smaller {
    @include fluid-type(36px, 72px, 320px, 1920px);
  }


  &.--pink {
    color: var(--pink);
  }

  &.--middle-pink {
    color: var(--middlePink);
  }

  &.--middle-blue {
    color: var(--middleBlue);
  }
}

.txt-products-section__description {
  font-weight: 300;
  @include fluid-type(14px, 24px, 320px, 1920px);

  &.--bigger {
    @include fluid-type(18px, 36px, 320px, 1920px);
  }

  &.--white {
    color: var(--white);
  }

  &.--nowrap {
    white-space: nowrap;
  }
}

.txt-products-section__anchor-list {
  margin-top: 30px;
}

.txt-products-section__anchor-list__item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  text-decoration: none;
  color: var(--black);
}

.txt-products-section__icon {
  margin-right: 15px;

  img {
    max-width: 30px;
    max-height: 30px;
  }
}

.txt-products-section__section-name {
  @include fluid-type(16px, 36px, 320px, 1920px);
  font-weight: 700;

  &.--blue {
    color: var(--blue);
  }

  &.--middle-pink {
    color: var(--middlePink);
  }

  &.--middle-blue {
    color: var(--middleBlue);
  }

  &.--white {
    color: var(--white);
  }
}

@media only screen and (max-width: 1024px) {
  .txt-products-section__container {
    flex-direction: column;
    max-width: 85%;
  }

  .txt-products-section__image {
    width: 95%;
    margin-top: 60px;
  }
  
  // Text content classes
  .txt-products-section__content {
    width: 100%;
  }

  .txt-products-section__title {
  
    br {
      display: none;
    }

    &.--nowrap {
      white-space: normal;
    }
  }

  .txt-products-section__description {

    br {
      display: none;
    }

    &.--nowrap {
      white-space: normal;
    }
  }

}

@media only screen and (max-width: 460px) {

  .txt-products-section__icon {
    img {
      width: 20px;
      height: auto;
    }
  }

  .txt-products-section__image {
    margin-top: 30px;
  }
}