.flickity-page-dots {
  bottom: -40px !important;
}

.--black {

  .flickity-page-dots {
    bottom: -10px !important;
    counter-reset: a;
  }

  .flickity-page-dots .dot::after {
    font-size: 16px;
    content: counter(a);
    display: inline-block;
    counter-increment: a;
    font-weight: 600;
    text-align: center;
    padding-top: 6px;
  }

  .dot {
    background-color: var(--middleBlue) !important;
    width: 30px;
    height: 30px;

    &.is-selected {
      color: var(--white) !important;
      background: var(--black) !important;
    }
  }
}

.dot {
  border: 1px solid var(--white);
  background-color: transparent !important;
  opacity: 1 !important;

  &.is-selected {
    background: var(--white) !important;
  }
}

.single {
  .flickity-ready .carousel-cell {
    height: 100% !important;
  }

  .flickity-prev-next-button {
    width: 70px;
    height: 70px;
    background: var(--white);
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
    background-size: 28px 16px;
    background-repeat: no-repeat;
    background-position: center center;

    svg {
      display: none;
    }

    &.previous {
      left: -70px;
      background-image: url('../images/single/previous-icon.svg');
    }
    
    &.next {
      right: -70px;
      background-image: url('../images/single/next-icon.svg');
    }
  }
}

@media only screen and (max-width: 1024px) {
  .rellax {
    transform: translate3d(0,0,0) !important;
  }
}

@media only screen and (max-width: 460px) {

  .--black {

    .flickity-page-dots {
      bottom: -40px !important;
      counter-reset: a;
    }
  }
}