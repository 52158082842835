// Structure
.txt-footer {
  width: 100%;
  height: 100vh;
  color: var(--white);
  background-color: var(--black);
  scroll-snap-align: start;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}

.txt-footer__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// Box
.txt-footer__box {
  width: 445px;
  border: 1px solid var(--white);
  color: var(--white);
  padding: 60px 50px;
  margin-right: 50px;

  a {
    color: var(--white);
    text-decoration: none;
    display: inline-block;
  }
}

.txt-footer__title {
  @include fluid-type(36px, 55px, 320px, 1920px);
  font-weight: 800;
  margin-bottom: 30px;
}

.txt-footer__mail {
  @include fluid-type(18px, 24px, 320px, 1920px);
  margin-bottom: 5px;
}

.txt-footer__phone {
  @include fluid-type(18px, 24px, 320px, 1920px);
  font-weight: 600;
}

.txt-footer__social {
  margin-top: 40px;
}

.txt-footer__social__item {
  display: inline-block;
  width: 45px;
  height: 45px;
  background-size: auto 25px;
  background-repeat: no-repeat;
  background-position: left center;
  margin-right: 10px;
  cursor: pointer;

  &.--facebook {
    background-image: url('../images/footer/facebook-icon.svg');
  }

  &.--instagram {
    background-image: url('../images/footer/instagram-icon.svg');
  }

}


// Address
.txt-footer__address-wrapper {
  @include fluid-type(14px, 16px, 320px, 1920px);
}

.txt-footer__address__content {
  max-width: 600px;
}

.txt-footer__address {
  display: flex;
  padding: 40px 0;
}

.txt-footer__country {
  width: 100%;
  margin-right: 20px;
}

.txt-footer__address-name {
  font-weight: 700;
  margin-bottom: 20px;
}

.txt-footer__links {
  padding: 40px 0;
  border-top: 1px solid #333;
  border-bottom: 1px solid #333;
}
.txt-footer__links__item {
  color: var(--white);
  display: inline-block;
  margin-right: 60px;
  text-decoration: none;
}

.txt-footer__signature {
  padding: 40px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.txt-footer__logo {}

.txt-footer__copyright {
  font-size: 12px;
  color: #A1A1A1;
}

@media only screen and (max-width: 1024px) {

  .txt-footer__content {
    flex-direction: column;
  }

  .txt-footer__box {
    width: 100%;
    margin-right: 0;
  }

  .txt-footer__content {
    max-width: 85%;
    margin: 0 auto;
  }

  .txt-footer__box {
    padding: 30px;
  }

  .txt-footer__social {
    margin-top: 10px;
  }

  .txt-footer__links {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
  }

  .txt-footer__links__item {
    margin-right: 0;
  }

  .txt-footer__mail,
  .txt-footer__phone {
    display: block !important;
  }

  .txt-footer__signature {
    flex-direction: column;
    padding: 20px 0;
  }
  
  .txt-footer__address {
    padding: 20px 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;

    br {
      display: none;
    }
  }

  .txt-footer__logo {
    margin-bottom: 20px;
  }

}

@media only screen and (max-width: 460px) {

  .txt-footer {
    // align-items: flex-end;
  }

}