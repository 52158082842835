.txt-product-testimonials__wrapper {
  width: 100%;
  max-width: 75%;
  margin: 0 auto;
  margin-top: 20vh;
  margin-bottom: 20vh;
}

.txt-product-testimonials {
  display: flex;
  flex-direction: row;
}

.txt-product-testimonials__title {
  font-family: 'Prompt', sans-serif;
  @include fluid-type(24px, 36px, 320px, 1920px);
  font-weight: 800;
  margin: 0 0 30px 20px;
}

.txt-product-testimonials__item {
  width: 50%;
  height: 400px;
  border-radius: 6px;
  margin: 0 20px;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--lightBlue);
}

.txt-product-testimonials__name {
  font-size: 18px;
  font-weight: 700;
}

.txt-product-testimonials__role {
  margin-bottom: 40px;
}

.txt-product-testimonials__conpany {
  width: 157px;

  img {
    width: 100%;
    height: auto;
  }
}

.txt-product-testimonials__content {
  font-weight: 300;
}

@media only screen and (max-width: 1024px) {

  .txt-product-testimonials {
    flex-direction: column;
  }

  .txt-product-testimonials__wrapper  {
    max-width: 85%;
  }

  .txt-product-testimonials__item {
    width: 95%;
    margin-bottom: 15px;
  }
  
}

@media only screen and (max-width: 520px) {

  .txt-product-testimonials__wrapper  {
    max-width: 100%;
  }

  .txt-product-testimonials__item {
    padding: 30px;
    width: 95%;
    margin: 0 8px 15px;
  }

  .single .flickity-prev-next-button {
    display: none;
  }

  .txt-product-testimonials {
    margin: 60px 0;
  }
}