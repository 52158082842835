// Component structure
.txt-testimonials-section {
  width: 100%;
  height: 100vh;
  display: flex;
}

.txt-testimonials-section__block {
  background-color: var(--blue);
  width: 45%;
  min-height: 100vh;
  scroll-snap-align: start;
  display: flex;
  justify-content: center;
  align-items: center;
}

// Testimonial items

.txt-testimonials {
  max-width: 450px;
  width: 90%;
}

.txt-testimonial {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--white);
}

.txt-testimonial__metadata__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.txt-testimonial__metadata {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.txt-testimonial__avatar {
  width: 70px;
  height: 70px;
  overflow: hidden;
  border-radius: 70px;
  margin-bottom: 15px;

  img {
    width: 70px;
    height: auto;
  }
}

.txt-testimonial__name {
  font-weight: 700;
}

.txt-testimonial__role {
  font-weight: 400;
}

.txt-testimonial__business {
  font-weight: 600;
  color: var(--pink);
  text-transform: uppercase;
}

.txt-testimonial__conent {
  @include fluid-type(14px, 24px, 320px, 1920px);
  text-align: center;
  font-weight: 400;
  font-style: oblique;
  margin: 20px 0;
}


// Clients section
.txt-testimonials-section__clients {
  width: 55%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.txt-testimonials-section__clients__content {}

.txt-testimonials-section__clients__client-list {
  width: 100%;
  max-width: 510px;
}

@media only screen and (max-width: 1024px) {
  .txt-testimonials-section {
    height: 100vh;
  }

  .txt-testimonials-section {
    flex-direction: column;
  }

  .txt-testimonials-section__block {
    width: 100%;
    min-height: 50vh;
  }

  .txt-testimonials-section__clients {
    width: 100%;
    min-height: 50vh;
  }

}

@media only screen and (max-width: 768px) {
  .txt-testimonials-section__clients__content {
    max-width: 85%;
  }
}

@media only screen and (max-width: 460px) {

  .txt-testimonial {
    align-items: flex-start;
  }

  .txt-testimonials {
    max-width: 85%;
  }

  .txt-testimonial__metadata__wrapper {
    flex-direction: row;
    justify-content: flex-start;
  }

  .txt-testimonial__metadata {
    align-items: flex-start;
    margin-left: 10px;
  }

  .txt-testimonial__conent {
    text-align: left;
    margin: 10px 0;
  }

  .txt-testimonial__avatar {
    width: 50px;
    height: 50px;

    img {
      width: 50px;
      height: auto;
    }
  }

  .txt-testimonials-section__clients__content {
    max-width: 85%;
  }

}