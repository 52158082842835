.txt-hamburger {
  display: none;
  width: 30px;
  height: 22px;
  position: relative;
  z-index: 3;
}

.txt-hamburger__line {
  width: 30px;
  height: 2px;
  background-color: var(--blue);
  margin-bottom: 5px;
  transform-origin: center center;
  transition: .3s ease;
}

.is-hamburger-opened {

  .txt-hamburger__line {
    background-color: var(--black) !important;
  }

  .txt-hamburger__line:first-of-type {
    opacity: 0;
  }

  .txt-hamburger__line:nth-of-type(2n) {
    transform: translateY(2px) rotate(45deg);
  }

  .txt-hamburger__line:nth-of-type(3n) {
    transform: translateY(-5px) rotate(-45deg);
  }

  .txt-hamburger__line:last-of-type {
    opacity: 0;
  }
}

body[data-theme='white'] {
  .txt-hamburger__line {
    background-color: var(--white);
  }
}

body[data-theme='footer'] {
  .txt-hamburger__line {
    background-color: var(--white);
  }
}

body[data-theme='mixed'] {
  .txt-hamburger__line {
    background-color: var(--white);
  }
}

@media only screen and (max-width: 1024px) {

  .txt-hamburger {
    display: block;
    pointer-events: auto;
  }

}

@media only screen and (max-width: 460px) {

}