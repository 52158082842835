// Structure
.txt-block-progress {
  width: 100%;
  max-width: 75%;
  margin: 0 auto;
  margin-top: 10vh;
  background-color: var(--lightBlue);
  padding: 100px;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
   
  &:before {
    content: '';
    width: 138px;
    height: 120px;
    background-size: contain;
    background-image: url('../images/sms-mkt/star-pattern.svg');
    position: absolute;
    left: -50px;
    bottom: -50px;
    z-index: -1;
  }
}
.txt-block-progress__container {
  width: 60%;
}

// Text section
.txt-block-progress__content {
}

.txt-block-progress__text {
  @include fluid-type(24px, 48px, 320px, 1920px);
  font-weight: 800;
  position: relative;
  margin-bottom: 20px;
  line-height: 1.2;

  &:before {
    content: '';
    width: 50px;
    height: 2px;
    display: block;
    background: var(--pink);
    position: absolute;
    left: 0;
    top: -20px;
  }
}

.txt-block-progress__source {
  font-weight: 600;
  @include fluid-type(14px, 16px, 320px, 1920px);
}

// Progress
.txt-progress__bars__container {
  width: 40%;
}

.txt-progress__bars__item {
  width: 100%;
  display: flex;
  flex-direction: column;
  &.--pink {

    .txt-progress__bars__labels__item {
      @include fluid-type(18px, 24px, 320px, 1920px);
    }

    .txt-progress__bars__bg {
      height: 40px;
      border: 1px solid var(--white);
      background-color: var(--white);
    }

    .txt-progress__bars__progress {
      background-color: var(--pink);
    }

  }
}

.txt-progress__bars__labels {
  display: flex;
  justify-content: space-between;
}

.txt-progress__bars__labels__item {
  &.--bold {
    font-weight: 700;

    &:after {
      content: '%';
    }
  }
}

.txt-progress__bars__bg {
  width: 100%;
  height: 20px;
  box-sizing: border-box;
  border-radius: 6px;
  border: 1px solid #D6D4E3;
  margin: 5px 0 20px 0;
  position: relative;
  overflow: hidden;
}

.txt-progress__bars__progress {
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 6px;
  background-color: #D6D4E3;
  transform: translateX(-100%);
  transition: transform .6s ease;

  &.is-loaded {
    transform: translateX(0);
  }
}

@media only screen and (max-width: 1024px) {
  .txt-block-progress {
    flex-direction: column;
    max-width: 85%;
    padding: 80px;
  }

  .txt-block-progress__container {
    width: 100%;
  }

  .txt-progress__bars__container {
    width: 100%;
  }
  
  .txt-block-progress__text {
    margin-bottom: 30px;
  }

  .txt-block-progress__source {
    margin-top: 20px;
    margin-bottom: 40px;
  }

}

@media only screen and (max-width: 460px) {
  .txt-block-progress {
    padding: 40px;
    padding-top: 60px;
  }

}