:root {
  --black: #222222;
  --white: #ffffff;
  --blue: #3D2ECE;
  --middleBlue: #D0CDEE;
  --lightBlue: #F4F3FF;
  --pink: #FF8BBC;
  --middlePink: #FCCADA;
  --lightPink: #FFE2EB;
}
