.txt-stats {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.txt-stats__content {
  width: 100%;
  max-width: 75%;
  margin: 0 auto;
}
.txt-stats__title {
  @include fluid-type(32px, 64px, 320px, 1920px);
  font-weight: 800;
  width: 85%;
  line-height: 1.2;
  margin-bottom: 50px;
}

.txt-stats__list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
}

.txt-stats__item {
  width: 100%;
}

.txt-stats__value {
  font-size: 96px;
  font-weight: 700;
  color: var(--pink);
}

.txt-stats__description { }

.txt-stats__source {
  font-size: 14px;
  margin-top: 30px;
  color: #666;
}

@media only screen and (max-width: 1024px) {
  .txt-stats__title {
    width: 100%;
  }

  .txt-stats__list {
    display: flex;
    flex-direction: column;
  }

  .txt-stats__content {
    max-width: 85%;
  }
}

@media only screen and (max-width: 460px) {}