.txt-section-indicator {
  width: 110px;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
  opacity: 1;
  transition: .3s ease;
}
.txt-sectionindicator__content {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.txt-sectionindicator__item {
  width: 65px;
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 80px;
  transition: .3s ease;
  margin-top: 20px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 25px 25px;

  &:hover {
    background-color: #291CA5;
  }

  &.--text {
    background-image: url('../images/home/icon-text.svg');
  }

  &.--sms {
    background-image: url('../images/home/icon-sms.svg');
  }
  &.--video {
    background-image: url('../images/home/icon-video.svg');
  }

  &.--review {
    background-image: url('../images/home/icon-review.svg');
  }

  &.--beacon {
    background-image: url('../images/home/icon-beacon.svg');
  }
}

body[data-section='reviewAlert'],
body[data-section='reviewBeacon'] {
  
  .txt-sectionindicator__item {

    &:hover {
      background-color: var(--white);
    }
  
  
    &.--text {
      background-image: url('../images/home/icon-text-blue.svg');
    }
  
    &.--sms {
      background-image: url('../images/home/icon-sms-blue.svg');
    }
    &.--video {
      background-image: url('../images/home/icon-video-blue.svg');
    }
  
    &.--review {
      background-image: url('../images/home/icon-review-blue.svg');
    }
  
    &.--beacon {
      background-image: url('../images/home/icon-beacon-blue.svg');
    }
  }

}

body[data-section='textChat'] {
  .txt-sectionindicator__item {
    &:nth-child(1) {
      background-color: #291CA5;
    }
  }
}

body[data-section='smsMkt'] {
  .txt-sectionindicator__item {
    &:nth-child(2) {
      background-color: #291CA5;
    }
  }
}

body[data-section='videoCalls'] {
  .txt-sectionindicator__item {
    &:nth-child(3) {
      background-color: #291CA5;
    }
  }
}

body[data-section='reviewAlert'] {
  .txt-sectionindicator__item {
    &:nth-child(4) {
      background-color: var(--white);
    }
  }
}

body[data-section='reviewBeacon'] {
  .txt-sectionindicator__item {
    &:nth-child(5) {
      background-color: var(--white);
    }
  }
}


body[data-section='clients'],
body[data-section='footer'] {

  .txt-section-indicator {
    display: none;
  }

}

@media only screen and (max-width: 1024px) {
  .txt-section-indicator {
    display: none;
  }
}