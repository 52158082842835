.txt-plans {
  background-color: var(--lightBlue);
  width: 100%;
  min-height: 100vh;
  padding: 60px 0 30px 0;
}

.txt-plans__wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 85%;
  margin: 0 auto;
  padding-top: 60px;
}

.txt-plans__container {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.txt-plan__title__container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.txt-plan__title {
  @include fluid-type(24px, 36px, 320px, 1920px);
  font-weight: 800;
}

.txt-plan__check-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.txt-plan__check-list__item {
  margin-left: 25px;

  &:before {
    content: '✓';
    display: inline-block;
    margin-right: 5px;
  }
}

.txt-plans__item {
  max-width: 445px;
  width: 33%;
  border: 1px solid var(--blue);
  padding: 60px;
  text-decoration: none;
  color: var(--black);
  transition: opacity .6s ease;
  
  &:nth-child(2) {
    margin: 0 45px;
  }
}

.txt-plans__name,
.txt-plans__value {
  @include fluid-type(24px, 55px, 320px, 1920px);
  line-height: 1;
  font-weight: 800;
}

.txt-plans__value {
  color: var(--blue);

  &:after {
    content: 'Month';
    font-size: 18px;
    color: var(--black);
    display: inline-block;
    margin-left: 5px;
    font-weight: 400;
  }
}

.txt-plans__features {
  list-style: none;
  margin: 0;
  padding: 0;
  margin: 40px 0;
}

.txt-plans__feature {
  @include fluid-type(18px, 24px, 320px, 1920px);
  margin-bottom: 10px;

  &.--not-avaliable {
    color: #BDBDBD;
    text-decoration: line-through;
  }
}

@media only screen and (max-width: 1280px) {

  .txt-plans__item {
    &:nth-child(2) {
      margin: 0 20px;
    }
  }

}

@media only screen and (max-width: 1024px) {

  .txt-plans__container {
    flex-direction: column;
  }

  .txt-plans__item {
    width: 100%;
    max-width: 100%;

    &:nth-child(2) {
      margin: 30px 0;
    }
  }

  .txt-plans__wrapper {
    padding: 60px 0;
  }

  .txt-plan__title__container {
    flex-direction: column;
    align-items: flex-start;
  }

  .txt-plan__check-list__item {
    margin: 0;
    margin-right: 20px;
  }

  .txt-plan__title {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 1024px) {
  .txt-plans__item {
    padding: 25px;
  }

  .txt-plan__check-list {
    flex-direction: column;
  }
}