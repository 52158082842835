.txt-header {
  width: 100%;
  padding: 30px 40px;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  z-index: 3;
  pointer-events: none;

  // display: none;
}

.txt-header__logo {
  width: 172px;
  height: 45px;
  display: inline-block;
  background-image: url('../images/logo/txtar-logo-blue.svg');
  pointer-events: auto;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  z-index: 3;
}

.txt-header__nav {
  pointer-events: auto;
}

.txt-header__nav__item {
  display: inline-block;
  padding: 20px 25px;
  text-decoration: none;
  color: var(--black);
  font-weight: 700;
  opacity: 0.8;
  transition: .3s ease;

  &:hover {
    opacity: 1;
  }

  &:nth-child(3) {
    margin-right: 25px;
  }

  &.--submenu-item {
    cursor: pointer;
    position: relative;

    &::before {
      content: '';
      width: 140px;
      height: 90px;
      position: absolute;
      left: 0;
      top: 0;
    }

    &::after {
      content: '';
      display: inline-block;
      width: 10px;
      height: 10px;
      background-image: url('../images/header/chevron-icon.svg');
      margin-left: 5px;
      background-repeat: no-repeat;
      background-position: center center;
    }

    &:hover {
      opacity: 1;

      .txt-submenu {
        opacity: 1;
        pointer-events: auto;
        transform: translateY(0px);
      }
    }
  }
}

// Submenu

.txt-submenu {
  width: 535px;
  box-shadow: 0px 2px 10px rgba(0,0,0,.2);
  border-radius: 6px;
  background: var(--white);
  position: absolute;
  left: 0;
  top: 75px;
  z-index: 999;
  opacity: 0;
  pointer-events: none;
  transform: translateY(-10px);
  transition: .3s ease;
}

@media only screen and (min-width: 1024px) {
  .txt-submenu {
    left: -95%;
  }
}

.txt-submenu__indicator {
  width: 100px;
  height: 3px;
  position: absolute;
  top: 0;
  left: 20px;
  
  background-color: var(--blue);
}

@media only screen and (min-width: 1024px) {
  .txt-submenu__indicator {
    left: 150px;
  }
}

.txt-submenu__links {
  padding: 40px;
  border-bottom: 1px solid var(--lightBlue);
}

.txt-submenu__links {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
}

.txt-submenu__column {
  display: flex;
  flex-direction: column;
}

.txt-submenu__title {
  font-size: 24px;
  color: #C1BEDF;
}

.txt-submenu__link__item {
  width: 100%;
  font-size: 14px;
  color: var(--black);
  text-decoration: none;
  display: inline-block;
  margin-top: 30px;
  opacity: 0.8;
  transition: .3s ease;

  &:hover {
    opacity: 1;
  }

  &:before {
    content: '';
    width: 24px;
    height: 24px;
    display: inline-block;
    transform: translateY(5px);
    margin-right: 25px;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  &.--text {
    &:before {
      background-image: url('../images/header/chat-icon.svg');
    }
  }

  &.--sms {
    &:before {
      background-image: url('../images/header/sms-icon.svg');
    }
  }

  &.--video {
    &:before {
      background-image: url('../images/header/video-icon.svg');
    }
  }


  &.--review {
    &:before {
      background-image: url('../images/header/review-icon.svg');
    }
  }

  &.--beacon {
    &:before {
      background-image: url('../images/header/beacon-icon.svg');
    }
  }
  
}

.txt-submenu__all-products {
  padding: 40px;
  display: inline-block;
  font-size: 14px;
  color: var(--blue);
  text-decoration: none;
  font-weight: 400;
  
  &:hover {
    &:after {
      transform: translateX(10px);
    }
  }
  
  &:after {
    content: '';
    transition: .3s ease;
    width: 15px;
    height: 9px;
    display: inline-block;
    background-image: url('../images/header/arrow-right.svg');
    background-repeat: no-repeat;
    background-size: contain;
    margin-left: 80px;
  }

  span {
    font-weight: 800;
  }
}

// Overrides

body[data-theme='white'] {

  .txt-header__nav__item {
    color: var(--white);
  }

  .txt-header__nav__item {
    &.--submenu-item {
      &::after {
        background-image: url('../images/header/chevron-icon-white.svg');
      }
    }
  }

  .txt-header__logo {
    background-image: url('../images/logo/txtar-logo-white.svg');
  }
}

body[data-theme='mixed'] {

  .txt-header__logo {
    background-image: url('../images/logo/txtar-logo-white.svg');
  }
}

body[data-theme='footer'] {

  .txt-header__logo {
    background-image: url('../images/logo/txtar-logo-white.svg');
  }

  .txt-header__nav__item {
    color: var(--white);
  }

  .txt-header__nav__item {
    &.--submenu-item {
      &::after {
        background-image: url('../images/header/chevron-icon-white.svg');
      }
    }
  }
}


@media only screen and (max-width: 1024px) {

  .txt-header {
    padding: 20px;
  }

  .txt-header__logo {
    width: 140px;
    height: 36px;
  }

  .txt-header__nav {
    position: fixed;
    left: 0;
    top: 0;
    background-color: var(--white);
    width: 100%;
    height: 100vh;
    padding: 90px 0 30px 0;
    overflow: scroll;
    z-index: 1;
    opacity: 0;
    pointer-events: none;

    .txt-btn {
      display: block;
      width: 85%;
      margin: 0 auto;

      &.--cta {
        background: var(--blue) !important;
        border: 2px solid var(--blue) !important;
        color: var(--white) !important;
        font-size: 16px;
      }
    }
  }

  .txt-header__nav__item {
    color: var(--black) !important;
    width: 85%;
    margin: 0 auto;
    display: block;
    opacity: 1;
    padding: 20px 0;

    &.--ghost-sm {
      padding: 20px 35px;
      border-radius: 6px;
      text-align: center;
      margin-bottom: 20px;
      border: 2px solid var(--black);
    }

    &.--submenu-item {
      padding: 0;

      &::after {
        display: none;
      }
    }

    &:nth-child(3) {
      margin: 0 auto;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  .txt-submenu {
    padding: 0;
    position: relative;
    opacity: 1;
    width: 100%;
    top: 0;
    box-shadow: none;
    background: transparent;
    transform: translateY(0);
  }

  .txt-submenu__links {
    display: flex;
    flex-direction: column;
    padding: 0;
    grid-gap: 0;
    border: none;
  }

  .txt-submenu__title {
    display: none;
  }

  .txt-submenu__indicator {
    display: none;
  }

  .txt-submenu__all-products {
    padding: 30px 0;
    font-size: 20px;
    border: none;

    &:after {
      display: none;
    }
  }

  .is-hamburger-opened {

    .txt-header__logo {
      background-image: url('../images/logo/txtar-logo-black.svg') !important;
    }

    .txt-header__nav {
      opacity: 1;
      pointer-events: auto;
    }

  }

}

@media only screen and (max-width: 460px) {
}

.inline-text{
  display: inline-block;
}
.msg-coming-soon{
  color: #EB5757;
  font-size: 11px;
  line-height: 19px;
  font-style: normal;
  font-weight: normal;
  display: block;
}