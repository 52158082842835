// Hero

.txt-home-hero__stars {
  position: absolute;
  right: -30px;
  top: 100px;
  z-index: 2;
}

.txt-home-hero__bubbles {
  position: absolute;
  left: -80px;
  top: 280px;
  z-index: 2;
  width: 150px;
  display: flex;
  flex-direction: column;
}

.txt-home-hero__bubbles__row {
  position: relative;
  opacity: 0;

  &.--agent {
    
    margin-bottom: 20px;
    
    .txt-home-hero__bubbles__bubble {
      height: 128px;
      background-color: var(--white);
      border-radius: 20px 20px 20px 0;
      box-shadow: -4px 4px 0 var(--black);
      left: 80px;
    }
  }

  &.--costumer {

    align-self: flex-end;

    .txt-home-hero__bubbles__bubble {
      height: 108px;
      background-color: var(--lightPink);
      border-radius: 20px 20px 0 20px;
      box-shadow: 4px 4px 0 var(--black);
      top: 0 !important;
      bottom: auto;
    }

  }
}

.txt-home-hero__bubbles__avatar {
  width: 60px;
  height: 60px;
  position: absolute;
  left: 0;
  bottom: 0;
}

.txt-home-hero__bubbles__bubble {
  width: 265px;
  padding: 18px 22px;
  border: 2px solid var(--black);
  position: absolute;
  bottom: 0;
}

.txt-home-hero__bubbles__name {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 10px;
}

.txt-home-hero__bubbles__message {
  font-size: 16px;
}

.txt-home-hero__rating {
  position: absolute;
  left: 30px;
  top: 200px;
  z-index: 2;
  width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.txt-home-hero__rating__browser {
  width: 320px;
  height: auto;
  opacity: 0;
  transform: translateX(-100px);
  transition: opacity .3s ease;
}

.txt-home-hero__rating__message {
  width: 320px;
  transform: translateY(-130px);
  opacity: 0;
  position: relative;
  left: -50px;
  transition: opacity .3s ease;
}

// Textchat

.txt-text-chat__comp {
  width: 310px;
  height: 151px;
  position: absolute;
  right: 65px;
  bottom: 110px;
  z-index: 3;
}

.txt-text-chat__comp__window {
  width: 310px;
  // opacity: 0;
  transition: opacity .3s ease;
}

.txt-text-chat__comp__button {
  width: 65px;
  height: 65px;
  position: absolute;
  right: 0;
  bottom: -85px;
  // opacity: 0;
  transition: opacity .3s ease;
}

.txt-text-chat__comp__product {
  position: absolute;
  top: 150px;
  left: -20px;
  width: 65%;
  height: auto;
  z-index: 2;
}

@media only screen and (max-width: 1024px) {
  .txt-text-chat__comp__product{
    display: none;
  }
}

// Video calls

.txt-video-call__comp {
  width: 233px;
  height: 146px;
  position: absolute;
  z-index: 2;
  top: 50px;
  right: 0;
}

.txt-video-call__comp__agent {
  width: 230px;
  height: 146px;
  position: relative;
  left: -20px;
  // opacity: 0;
}

.txt-video-call__comp__client {
  width: 233px;
  height: 124px;
  // opacity: 0;
}

// Review

.review-comp-bubble {
  width: 130px;
  height: 35px;
  position: absolute;
  right: 30%;
  bottom: 70px;
  z-index: 2;
}

// Beacon

.beacon-comp {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
}
.beacon-comp__left {
  width: 290px;
  height: 189px;
  position: absolute;
  left: -70px;
  bottom: 0;
}
.beacon-comp__right {
  width: 269px;
  height: 149px;
  position: absolute;
  left: 130px;
  bottom: 140px;
}

#textChat {
  .txt-snap__contept-image__mobile {
    display: none;
  }
}

#videoCalls {
  .txt-snap__contept-image__mobile {
    display: none;
  }
}

#reviewBeacon {
  .txt-snap__contept-image__mobile {
    display: none;
  }
}

@media only screen and (max-width: 1024px) {
  .txt-home-hero__rating {
    left: 80px;
  }

  .txt-home-hero__bubbles {
    left: -20px;
  }

  .txt-text-chat__comp {
    right: 85px;
  }

  .review-comp-bubble {
    bottom: 70px;
    right: 30%;
  }

}


@media only screen and (max-width: 460px) {

  // hero
  .txt-home-hero__stars {
    display: none;
  }

  .txt-home-hero__rating {
    display: none;
  }

  .txt-home-hero__bubbles {
    display: none;
  }

  // text-chat
  .txt-text-chat__comp {
    display: none;
  }

  #textChat {
    .txt-snap__contept-image {
      display: none;
    }
  }

  #textChat {
    .txt-snap__contept-image__mobile {
      width: 100%;
      display: block;
    }
  }

  #videoCalls {
    .txt-snap__contept-image {
      display: none;
    }
  }

  #videoCalls {
    .txt-snap__contept-image__mobile {
      width: 100%;
      display: block;
    }
  }

  #reviewBeacon {
    .txt-snap__contept-image {
      display: none;
    }
  }

  #reviewBeacon {
    .txt-snap__contept-image__mobile {
      width: 100%;
      display: block;
    }
  }

  .txt-video-call__comp {
    display: none;
  }

  .beacon-comp {
    display: none;
  }

  .review-comp-bubble {
    right: 20%;
    top: 170px;
  }
}