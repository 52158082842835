// Structure

.txt-snap {
  width: 100%;
  height: 100vh;
  overflow: scroll;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
}

.txt-snap__section {
  width: 100%;
  height: 100vh;
  scroll-snap-align: start;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  &.--light-blue {
    background-color: var(--lightBlue);
  }

  &.--blue {
    background-color: var(--blue);
  }

  &.--light-pink {
    background-color: var(--lightPink);
  }
}

// Content

.txt-snap__content {
  display: flex;
  max-width: 75%;
  width: 100%;
  margin: 0 auto;
  margin-top: 60px;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.txt-snap__text-block {
  width: 40%;
  z-index: 2;
}

.txt-snap__image-block {
  width: 50%;
  position: relative;
}

.txt-snap__description {
  margin: 0;
  margin-bottom: 40px;

  &.--nowrap {
    white-space: nowrap;
  }

  &.--bigger {
    @include fluid-type(18px, 24px, 320px, 1920px);
  }

  &.--white {
    color: var(--white);
  }
}

.txt-snap__contept-image {
  width: 100%;
  height: auto;

  &.--homehero {
    width: 95%;
    float: right;
  }
}

.txt-snap__contept-image_mobile {
  width: 100%;
  height: auto;
  display: none;
}

.txt-snap__headline {
  @include fluid-type(32px, 72px, 320px, 1920px);
  font-weight: 800;
  line-height: 1;
  margin: 0;
  margin-bottom: 30px;

  &.--nowrap {
    white-space: nowrap;
  }
}

.txt-snap__title {
  @include fluid-type(42px, 90px, 320px, 1920px);
  font-size: 90px;
  font-weight: 800;
  line-height: 1;
  color: var(--pink);
  margin: 0;
  margin-bottom: 20px;

  &.--blue {
    color: var(--blue);
  }

  &.--nowrap {
    white-space: nowrap;
  }
}

// Stat block

.txt-snap__stats-block {
  margin-top: 40px;
}

.txt-snap__stats {
  font-size: 144px;
  font-weight: 800;
  -webkit-text-stroke-width: 2px;
  color: var(--blue);
  -webkit-text-stroke-color: var(--pink);
  text-shadow: -3px 3px 0 var(--pink);
  margin: 0;
  line-height: 1;
  letter-spacing: 3px;

  &.--blue {
    color: var(--lightPink);
    -webkit-text-stroke-color: var(--blue);
    text-shadow: -3px 3px 0 var(--blue);
  }

}

.txt-snap__stats__source {
  font-size: 12px;
  margin-top: -30px;

  &.--white {
    color: var(--white);
  }
}

@media only screen and (max-width: 1024px) {

  .txt-snap__content {
    flex-direction: column;
  }

  .txt-snap__content {
    max-width: 85%;
  }

  .txt-snap__text-block {
    width: 100%;
  }

  .txt-snap__title {

    br {
      display: none;
    }
  
    &.--nowrap {
      white-space: normal;
    }
  }
  
  .txt-snap__image-block {
    width: 95%;
    margin-top: 60px;
  }

  .txt-snap__headline {
    &.--nowrap {
      white-space: normal;

      br {
        display: none;
      }

    }
  }

  .txt-snap__stats-block {
    display: none;
  }

  .txt-snap__description {
    margin: 0;
    margin-bottom: 40px;

    br {
      display: none;
    }
  
    &.--nowrap {
      white-space: normal;
    }
  }

}

@media only screen and (max-width: 460px) {
  .txt-snap__image-block {
    margin-top: 30px;
  }

  .txt-snap__content {
    margin-top: 30px;
  }

  .txt-snap__contept-image {

    &.--homehero {
      display: none;
    }
  }


  .txt-snap__contept-image_mobile {
    display: block;
  }
}