.txt-buble-action__container {
  width: 100%;
  margin-bottom: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.txt-buble-action {
  width: 460px;
  height: auto;
}

@media only screen and (max-width: 1024px) {
  
}

@media only screen and (max-width: 520px) {
  .txt-buble-action__container {
    width: 85%;
    margin: 0 auto;
    margin-bottom: 60px;
  }

  .txt-buble-action {
    width: 100%;
  }
}