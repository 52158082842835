.txt-btn {
  @include fluid-type(14px, 16px, 320px, 1920px);
  padding: 20px 35px;
  border-radius: 6px;
  font-weight: 600;
  display: inline-block;
  text-decoration: none;
  transition: .3s ease;
  border: 1px solid var(--black);
  color: var(--black);
  text-align: center;

  opacity: 0.8;
    
  &:hover {
    opacity: 1;
  }

  &.--fixed-width {
    width: 325px;
  }

  &.--full-width {
    width: 100%;
  }
  
  &.--ghost-white {
    border: 1px solid var(--lightPink);
    color: var(--lightPink);
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }
  }

  &.--cta {
    color: var(--white);
    background-color: var(--blue);
    border: 1px solid var(--blue);
    opacity: 1;

    &:hover {
      background-color: darken(#3D2ECE, 5%);
      border: 1px solid darken(#3D2ECE, 5%);
    }
  }

  &.--cta-white {
    color: var(--blue);
    background-color: var(--white);
    border: 1px solid var(--white);
    opacity: 1;

    &:hover {
      background-color: darken(#FFF, 5%);
      border: 1px solid darken(#FFF, 5%);
    }
  }
}

// Overrides

body[data-theme='white'] {
  .txt-header {
    .txt-btn {
      &.--cta {
        color: var(--blue);
        background-color: var(--pink);
        border: 1px solid var(--pink);
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .txt-btn {
  
    &.--fixed-width {
      width: 100%;
    }
  }
}