// Structure
.txt-section {
  width: 100%;
}

.txt-section__container {
  width: 100%;
  max-width: 75%;
  min-height: 80vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  margin-top: 100px;

  &.--reverse {
    flex-direction: row-reverse;
  }
}

// Text block
.txt-section__text__block {
  width: 40%;
}
.txt-section__eyebrow {
  @include fluid-type(18px, 24px, 320px, 1920px);
  font-weight: 700;

  &.--blue {
    color: var(--blue);
  }

  &.--pink {
    color: var(--pink);
  }

  &.--icon {
    &:before {
      content: '';
      width: 25px;
      height: 25px;
      background-size: contain;
      background-repeat: no-repeat;
      background-repeat: center center;
      display: inline-block;
      transform: translateY(3px);
      margin-right: 8px;
    }
  }

  &.--text-chat {
    &:before {
      background-image: url('../images/single/chat-icon.svg');
    }
  }

  &.--sms-mkt {
    &:before {
      background-image: url('../images/single/sms-icon.svg');
    }
  }

  &.--video-calls {
    &:before {
      background-image: url('../images/single/video-icon.svg');
    }
  }


  &.--review-alert {
    &:before {
      background-image: url('../images/single/review-icon.svg');
    }
  }

  &.--review-beacon {
    &:before {
      background-image: url('../images/single/beacon-icon.svg');
    }
  }

}

.txt-section__title {
  @include fluid-type(36px, 64px, 320px, 1920px);
  margin: 0;
  line-height: 1;

  &.--headline {
  @include fluid-type(36px, 72px, 320px, 1920px);
  }

  &.--nowrap {
    white-space: nowrap;
  }
}
.txt-section__description {
  margin: 40px 0;
}

.txt-section__list {
  margin: 0;
  padding: 0;
  list-style: none;
  margin: 30px 0;
  display: flex;

  &.--blue {
    .txt-section__list__item {
      &:before {
        content: '';
        width: 15px;
        height: 10px;
        display: inline-block;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url('../images/single/check-icon-blue.svg');
        margin-right: 5px;
      }
    }
  }

  &.--pink {
    .txt-section__list__item {
      &:before {
        content: '';
        width: 15px;
        height: 10px;
        display: inline-block;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url('../images/single/check-icon-pink.svg');
        margin-right: 5px;
      }
    }
  }
}

.txt-section__list__item {
  @include fluid-type(14px, 16px, 320px, 1920px);
  margin-right: 30px;
  font-weight: 600;
}

// Image block
.txt-section__image__block {
  width: 45%;
  position: relative;

  &.--slider {
    width: 55%;

    &:before {
      content: '';
      width: 138px;
      height: 120px;
      background-image: url('../images/review-beacon/star-pattern.svg');
      background-size: contain;
      background-repeat: no-repeat;
      display: block;
      z-index: -1;
      position: absolute;
      left: 0;
      bottom: -10px;
    }
  }

  img {
    width: 100%;
    height: auto;
  }
}

.txt-section__image_mobile {
  display: none;
}

// Sad mail message
.txt-section__mail {
  display: flex;
  align-items: flex-end;
  transform: translateY(-30px);
}

.txt-section__mail__icon {
  width: 80px;
  height: auto;

  img {
    width: 100%;
    height: auto;
  }
}

.txt-section__mail__content {
  width: 100%;
  font-weight: 700;
}

@media only screen and (max-width: 1024px) {

  .txt-section__container {
    max-width: 85%;
    flex-direction: column;

    &.--reverse {
      flex-direction: column;
    }
  }

  .txt-section__text__block {
    width: 100%;
  }

  .txt-section__image__block {
    width: 100%;
    margin-top: 60px;

    &.--slider {
      width: 100%;

      &:before {
        opacity: 0;
      }
    }
  }

  .txt-section__title {
    br {
      display: none;
    }

    &.--nowrap {
      white-space: normal;
    }
  }

  .txt-section__description {

    br {
      display: none;
    }

    &.--nowrap {
      white-space: normal;
    }
  }
}

@media only screen and (max-width: 460px) {

  .txt-section__list {
    flex-direction: column;
  }

  .txt-section__description {
    margin: 15px 0;
  }

  .txt-section__image__block {
    margin-top: 10px;
  }

  .txt-section__container {
    min-height: 0;
    margin-bottom: 60px;
  }

  #smsLeadBubbles {
    .txt-section__image_mobile {
      display: block;
    }

    .txt-section__image {
      display: none;
    }

  }
}

.flag-coming-soon:after {
    content: "Coming soon";
    font-size: 14px;
    line-height: 48px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 48px;
    color: #3D2ECE;
    background: #E3E0FF;
    border-radius: 3px;
    padding: 5px 10px;
    margin-left: 28px;
    position: relative;
    top: -4px;
}